import React, { useState } from 'react';
import PageLayout from '../components/PageLayout';
import { Button } from "@/components/ui/button";
import { FaEnvelope } from 'react-icons/fa';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
    alert('Thank you for your message. We will get back to you soon!');
  };

  return (
    <PageLayout title="Contact Us" icon={FaEnvelope}>
      <div className="max-w-md mx-auto">
        <p className="mb-6 text-lg text-zinc-300">
          Have a question or comment? We'd love to hear from you. Please fill out the form below and we'll get back to you as soon as possible.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block mb-2 font-medium text-zinc-300">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-4 py-3 bg-transparent border-2 border-orange-500/50 rounded-xl leading-none text-white text-lg focus:outline-none focus:border-orange-500 transition-all duration-300"
            />
          </div>
          <div>
            <label htmlFor="email" className="block mb-2 font-medium text-zinc-300">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-3 bg-transparent border-2 border-orange-500/50 rounded-xl leading-none text-white text-lg focus:outline-none focus:border-orange-500 transition-all duration-300"
            />
          </div>
          <div>
            <label htmlFor="message" className="block mb-2 font-medium text-zinc-300">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows={4}
              className="w-full px-4 py-3 bg-transparent border-2 border-orange-500/50 rounded-xl leading-none text-white text-lg focus:outline-none focus:border-orange-500 transition-all duration-300"
            ></textarea>
          </div>
          <Button 
            type="submit"
            variant="primary"
            className="w-full py-3 rounded-xl"
          >
            Send Message
          </Button>
        </form>
      </div>
    </PageLayout>
  );
};

export default Contact;
