import React from 'react';
import PageLayout from '../components/PageLayout';
import { FaLock } from 'react-icons/fa';

const Privacy: React.FC = () => {
  return (
    <PageLayout title="Privacy Policy" icon={FaLock}>
      <div className="space-y-6">
        <p>At FanFabric, we are committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly. This Privacy Policy outlines how we collect, use, and protect your information when you interact with our website.</p>
        <section>
          <h2 className="text-2xl font-semibold mb-3 text-orange-500">Information We Collect</h2>
          <p>When you visit or make a purchase from our website, we may collect the following information:</p>
          <ul className="list-disc list-inside mt-2">
            <li><strong>Personal Information:</strong> Name, email address, billing and shipping addresses, payment information, and phone number.</li>
            <li><strong>Non-Personal Information:</strong> Browsing behavior, device information, and other anonymous data to improve the user experience on our site.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3 text-orange-500">How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul className="list-disc list-inside mt-2">
            <li>Process orders and deliver products</li>
            <li>Provide customer support</li>
            <li>Improve our website and services</li>
            <li>Send updates, promotions, and newsletters (with your consent)</li>
          </ul>
          <p className="mt-2">We will never sell, trade, or share your personal information with third parties without your consent, except when necessary to complete a transaction or comply with the law.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3 text-orange-500">Data Security</h2>
          <p>We take data security seriously and use industry-standard measures to protect your personal information. We implement encryption, secure payment processing, and other safeguards to ensure that your information is safe with us.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3 text-orange-500">Cookies</h2>
          <p>Our website uses cookies to enhance your browsing experience and gather insights on how users engage with our platform. You can manage or disable cookies in your browser settings.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3 text-orange-500">Your Rights</h2>
          <p>You have the right to access, update, or request the deletion of your personal information. If you wish to exercise these rights or have any concerns about how we handle your data, please contact us at [Insert Contact Information].</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3 text-orange-500">Changes to This Policy</h2>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review it periodically to stay informed about how we are protecting your information.</p>
        </section>
      </div>
    </PageLayout>
  );
};

export default Privacy;
