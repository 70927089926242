import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { supabase } from '@/lib/supabase';
import PageLayout from '@/components/PageLayout';
import { FaKey } from 'react-icons/fa';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams<{ token?: string }>();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (token) {
      // Handle flow with token (usually coming from email link)
      supabase.auth.verifyOtp({ token_hash: token, type: 'recovery' })
        .catch(() => setError('Invalid or expired reset link'));
    }
  }, [token]);
  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    try {
      setLoading(true);
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      alert('Password updated successfully!');
      navigate('/login');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred while resetting your password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageLayout title="Reset Password" icon={FaKey}>
      <div className="max-w-md mx-auto">
        <p className="mb-6 text-lg text-zinc-300">
          Enter your new password below.
        </p>
        <form onSubmit={handleResetPassword} className="space-y-6">
          <div>
            <label htmlFor="newPassword" className="block mb-2 font-medium text-zinc-300">
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="w-full px-4 py-3 bg-transparent border-2 border-orange-500/50 rounded-xl leading-none text-white text-lg focus:outline-none focus:border-orange-500 transition-all duration-300"
              disabled={loading}
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" className="block mb-2 font-medium text-zinc-300">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full px-4 py-3 bg-transparent border-2 border-orange-500/50 rounded-xl leading-none text-white text-lg focus:outline-none focus:border-orange-500 transition-all duration-300"
              disabled={loading}
            />
          </div>
          {error && (
            <div className="text-red-500 text-sm mt-2">
              {error}
            </div>
          )}
          <Button 
            type="submit"
            variant="primary"
            className="w-full py-3 rounded-xl"
            disabled={loading}
          >
            {loading ? 'Updating Password...' : 'Update Password'}
          </Button>
        </form>
      </div>
    </PageLayout>
  );
};

export default ResetPassword;

