import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Input } from './ui/input';
import { Button } from './ui/button';
import styles from './TeamRequestForm.module.css';

interface TeamRequestFormProps {
  onSubmit: (cityTeam: string) => void;
}

const statesList = [
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyoming' }
];

const TeamRequestForm: React.FC<TeamRequestFormProps> = ({ onSubmit }) => {
  const [city, setCity] = useState('');
  const [selectedState, setSelectedState] = useState<{ code: string; name: string } | null>(null);
  const [isStateOpen, setIsStateOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [teamName, setTeamName] = useState('');
  
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsStateOpen(false);
        setSearchTerm('');
      }
    };

    if (isStateOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isStateOpen]);

  const calculateDropdownPosition = useCallback(() => {
    if (dropdownRef.current && containerRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const bottomSpace = viewportHeight - dropdownRect.bottom;
      
      if (bottomSpace < 40) {
        window.scrollTo({
          top: window.scrollY + (40 - bottomSpace),
          behavior: 'smooth'
        });
      }
    }
  }, []);

  const handleDropdownToggle = () => {
    setIsStateOpen(!isStateOpen);
    if (!isStateOpen) {
      setSearchTerm('');
      requestAnimationFrame(calculateDropdownPosition);
    }
  };

  const handleStateSelect = (state: typeof statesList[0]) => {
    setSelectedState(state);
    setIsStateOpen(false);
    setSearchTerm('');
  };

  const filteredStates = searchTerm
    ? statesList.filter(state => 
        state.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : statesList;

  return (
    <div ref={containerRef} className={styles.container}>
      <Input
        type="text"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        placeholder="Enter your city..."
      />

      <div className="relative">
        <Button 
          variant="primary"
          className="w-full justify-start"
          onClick={handleDropdownToggle}
        >
          {selectedState ? selectedState.name : 'Select your state'}
        </Button>

        <AnimatePresence>
          {isStateOpen && (
            <motion.div
              ref={dropdownRef}
              className={styles.dropdownWrapper}
              initial={{ opacity: 0, y: -4 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -4 }}
              transition={{ duration: 0.15 }}
            >
              <div className={styles.searchWrapper}>
                <Input
                  ref={searchInputRef}
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search states..."
                />
              </div>
              
              <div className={styles.dropdownContent}>
                {filteredStates.map((state) => (
                  <button
                    key={state.code}
                    className={styles.dropdownItem}
                    onClick={() => handleStateSelect(state)}
                    type="button"
                  >
                    {state.name}
                  </button>
                ))}
                {filteredStates.length === 0 && (
                  <div className={styles.noResults}>
                    No states found
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {selectedState && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <Input
              type="text"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              placeholder="Enter your team name..."
            />
            {teamName && (
              <Button
                variant="primary"
                className="w-full mt-3"
                onClick={() => onSubmit(`${city}, ${selectedState.code} - ${teamName}`)}
              >
                Submit Request
              </Button>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TeamRequestForm;

