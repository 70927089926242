// src/App.tsx
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import MainContent from './components/MainContent';
import EmailVerificationMessage from './components/EmailVerificationMessage';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Contact from './pages/Contact';
import ResetPassword from './pages/ResetPassword';
import AdminLayout from './components/admin/layouts/AdminLayout';
import { Team } from './types/Team';
import './App.css';

// Lazy load admin components
const AdminDashboard = lazy(() => import('./components/admin/pages/AdminDashboard'));
const AdminTeams = lazy(() => import('./components/admin/pages/AdminTeams'));
const AdminProducts = lazy(() => import('./components/admin/pages/AdminProducts'));
const AdminOrders = lazy(() => import('./components/admin/pages/AdminOrders'));
const AdminUsers = lazy(() => import('./components/admin/pages/AdminUsers'));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const LoadingSpinner = () => <div>Loading...</div>; // Add your spinner component here
const App: React.FC = () => {
  const handleTeamSelect = (team: Team) => {
    console.log('Selected team:', team);
  };

  const handleTeamRequest = (request: string) => {
    console.log('Team request:', request);
  };

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <div className="App bg-zinc-900 text-white min-h-screen">
            <EmailVerificationMessage />
            <Header />
            <Routes>
              <Route 
                path="/" 
                element={
                  <MainContent 
                    handleTeamSelect={handleTeamSelect} 
                    handleTeamRequest={handleTeamRequest} 
                  />
                } 
              />
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route 
                path="/admin" 
                element={
                  <ProtectedRoute requireAdmin>
                    <AdminLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AdminDashboard />
                  </Suspense>
                } />
                <Route path="products" element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AdminProducts />
                  </Suspense>
                } />
                <Route path="orders" element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AdminOrders />
                  </Suspense>
                } />
                <Route path="users" element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AdminUsers />
                  </Suspense>
                } />
                <Route path="teams" element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AdminTeams />
                  </Suspense>
                } />
              </Route>
            </Routes>
          </div>
        </Router>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default App;
