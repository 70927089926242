import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { FaChartBar, FaBox, FaShoppingCart, FaUsers } from 'react-icons/fa';

const AdminLayout: React.FC = () => {
  const location = useLocation();
  
  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <div className="flex min-h-screen bg-zinc-800 overflow-hidden">
      <nav className="w-64 min-h-screen bg-zinc-900 p-6 flex-shrink-0">
        <h2 className="text-2xl font-bold text-orange-500 mb-6">Admin Portal</h2>
        <ul className="space-y-2">
          {[
            { path: '/admin', icon: FaChartBar, label: 'Dashboard' },
            { path: '/admin/teams', icon: FaUsers, label: 'Teams' },
            { path: '/admin/products', icon: FaBox, label: 'Products' },
            { path: '/admin/orders', icon: FaShoppingCart, label: 'Orders' },
            { path: '/admin/users', icon: FaUsers, label: 'Users' }
          ].map(({ path, icon: Icon, label }) => (
            <li key={path}>
              <Link 
                to={path} 
                className={`flex items-center px-4 py-2 rounded-lg transition-all duration-200 ${
                  isActive(path) 
                    ? 'bg-zinc-800 border border-orange-500/50 text-orange-500' 
                    : 'text-zinc-400 hover:bg-zinc-800/50 hover:text-zinc-200'
                }`}
              >
                <Icon className="mr-2" />
                <span>{label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <main className="flex-1 p-8 overflow-y-auto relative">
        <Outlet />
      </main>
    </div>
  );
};
export default AdminLayout;
