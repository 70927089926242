import React from 'react';
import { cn } from '@/lib/utils';
import Container from './Container';

interface SectionProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  contained?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const Section: React.FC<SectionProps> = ({
  children,
  contained = true,
  size = 'lg',
  className,
  ...props
}) => {
  const Content = contained ? Container : 'div';

  return (
    <section
      className={cn(
        'py-12 md:py-16 lg:py-20 bg-gradient-to-b from-zinc-900 to-black',
        className
      )}
      {...props}
    >
      <Content size={size}>
        {children}
      </Content>
    </section>
  );
};

export default Section;
